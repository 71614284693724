import firebase from 'firebase/app';
import API from '../utils/api';
import 'firebase/auth';

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  TRY_LOGIN,
  TRY_LOGIN_SUCCESS,
  TRY_LOGIN_FAILED,
  LOGOUT,
  LOGOUT_SUCCESS,
  SET_UNAUTHORIZED_PATH,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  ACTIVATE,
  ACTIVATE_SUCCESS,
  ACTIVATE_ERROR,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_ERROR,
  RECOVER_PASSWORD_CHANGE,
  RECOVER_PASSWORD_CHANGE_SUCCESS,
  RECOVER_PASSWORD_CHANGE_ERROR,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_ERROR,
  REFRESH_TOKEN_SUCCESS,
  CLEAR_AUTH_ERRORS
} from '../types';

const defaultError = {
  code: 500,
  message: 'Unexpected error'
};

// Actions
const tryLoginAction = (user) => ({
  type: TRY_LOGIN,
  payload: user
});

const tryLoginFailed = () => ({
  type: TRY_LOGIN_FAILED
});

const tryLoginSuccess = (user) => ({
  type: TRY_LOGIN_SUCCESS,
  payload: user
});

const loginAction = (user) => ({
  type: LOGIN,
  payload: user
});

const registerAction = (user) => ({
  type: REGISTER,
  payload: user
});

const registerSuccessAction = (data) => ({
  type: REGISTER_SUCCESS,
  payload: data
});

const registerErrorAction = (error) => ({
  type: REGISTER_ERROR,
  payload: error
});

const loginSuccessAction = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user
});

const loginErrorAction = (error) => ({
  type: LOGIN_ERROR,
  payload: error
});

const logoutAction = () => ({
  type: LOGOUT
});

const logoutActionSuccess = () => ({
  type: LOGOUT_SUCCESS
});

const unauthorizedPathAction = (path) => ({
  type: SET_UNAUTHORIZED_PATH,
  payload: path
});

const activateAction = (user) => ({
  type: ACTIVATE,
  payload: user
});

const activateActionSuccess = () => ({
  type: ACTIVATE_SUCCESS
});

const activateActionError = (error) => ({
  type: ACTIVATE_ERROR,
  payload: error
});

const recoverPasswordAction = () => ({
  type: RECOVER_PASSWORD
});

const recoverPasswordActionSuccess = () => ({
  type: RECOVER_PASSWORD_SUCCESS
});

const recoverPasswordActionError = (error) => ({
  type: RECOVER_PASSWORD_ERROR,
  payload: error
});

const recoverPasswordChangeAction = () => ({
  type: RECOVER_PASSWORD_CHANGE
});

const recoverPasswordChangeActionSuccess = () => ({
  type: RECOVER_PASSWORD_CHANGE_SUCCESS
});

const recoverPasswordChangeActionError = (error) => ({
  type: RECOVER_PASSWORD_CHANGE_ERROR,
  payload: error
});

const updateUserAction = () => ({
  type: UPDATE_USER
});

const updateUserActionSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: user
});

const updateUserActionError = (error) => ({
  type: UPDATE_USER_ERROR,
  payload: error
});

const refreshTokenAction = () => ({
  type: REFRESH_TOKEN
});

const refreshTokenActionSuccess = (data) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: data
});

const refreshTokenActionError = (error) => ({
  type: REFRESH_TOKEN_ERROR,
  payload: error
});

const clearErrorsAction = () => ({
  type: CLEAR_AUTH_ERRORS
});

export const tryLogin = () => async (dispatch) => {
  dispatch(tryLoginAction());

  const jsonSession = sessionStorage.getItem('session');

  if (jsonSession) {
    const session = JSON.parse(jsonSession);
    dispatch(tryLoginSuccess(session));
  } else {
    dispatch(tryLoginFailed());
  }
};

export const login = (data) => async (dispatch) => {
  dispatch(loginAction());

  try {
    const response = await API().post('/v1/auth/login', data);
    const session = response.data.data;

    sessionStorage.setItem('session', JSON.stringify(session));
    dispatch(loginSuccessAction(session));
  } catch (ex) {
    dispatch(
      loginErrorAction(ex.response ? ex.response.data : defaultError)
    );
  }
};

const getProvider = (name) => {
  const list = {
    google: () => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/userinfo.email');
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
      return provider;
    },
    github: () => {
      const provider = new firebase.auth.GithubAuthProvider();
      provider.addScope('read:user');
      provider.addScope('user:email');
      return provider;
    }
  };

  return list[name]();
};

const oauthLogin = async (dispatch, data) => {
  dispatch(loginAction());

  try {
    const response = await API().post('/v1/auth/oauth-login', data);
    const session = response.data.data;
    sessionStorage.setItem('session', JSON.stringify(session));
    dispatch(loginSuccessAction(session));
  } catch (ex) {
    dispatch(
      loginErrorAction(ex.response ? ex.response.data : defaultError)
    );
  }
};

export const loginWithThirdParty = ({ name, language }) => async (dispatch) => {
  const provider = getProvider(name);
  const result = await firebase.auth().signInWithPopup(provider);
  const token = await result.user.getIdToken();

  await oauthLogin(dispatch, {
    token,
    language
  });
};

export const register = (data) => async (dispatch) => {
  dispatch(registerAction());

  try {
    const response = await API().post('/v1/auth/register', data);
    dispatch(registerSuccessAction(response.data));
  } catch (ex) {
    dispatch(
      registerErrorAction(ex.response ? ex.response.data : defaultError)
    );
  }
};

export const logout = () => async (dispatch) => {
  dispatch(logoutAction());

  sessionStorage.removeItem('session');

  dispatch(logoutActionSuccess());
};

export const setUnauthorizedPath = (path) => async (dispatch) => {
  dispatch(unauthorizedPathAction(path));
};

export const activate = (email, activationCode) => async (dispatch) => {
  dispatch(activateAction());

  try {
    await API().post('/v1/auth/activate', {
      email,
      activationCode
    });

    dispatch(activateActionSuccess());
  } catch (ex) {
    dispatch(activateActionError(ex.response ? ex.response.data : defaultError));
  }
};

export const recoverPassword = (data) => async (dispatch) => {
  dispatch(recoverPasswordAction());

  try {
    await API().post('/v1/auth/requirePasswordReset', data);
    dispatch(recoverPasswordActionSuccess());
  } catch (ex) {
    dispatch(recoverPasswordActionError(ex.response ? ex.response.data : defaultError));
  }
};

export const recoverPasswordChange = (data) => async (dispatch) => {
  dispatch(recoverPasswordChangeAction());

  try {
    await API().post('/v1/auth/passwordReset', data);
    dispatch(recoverPasswordChangeActionSuccess());
  } catch (ex) {
    dispatch(recoverPasswordChangeActionError(ex.response ? ex.response.data : defaultError));
  }
};

export const updateUser = (user) => async (dispatch) => {
  dispatch(updateUserAction());

  try {
    const updatedUser = await API().put('/v1/users/me', user);
    dispatch(updateUserActionSuccess(updatedUser.data));

    // Update session storage
    const jsonSession = sessionStorage.getItem('session');

    if (jsonSession) {
      const session = JSON.parse(jsonSession);
      session.user = updatedUser.data;
      // console.log(session);
      sessionStorage.setItem('session', JSON.stringify(session));
    }
  } catch (ex) {
    dispatch(updateUserActionError(ex.response ? ex.response.data : defaultError));
  }
};

export const refreshToken = () => async (dispatch) => {
  dispatch(refreshTokenAction());

  try {
    const response = await API().post('/v1/auth/refresh');
    const session = response.data.data;
    sessionStorage.setItem('session', JSON.stringify(session));
    dispatch(refreshTokenActionSuccess(session));
  } catch (ex) {
    dispatch(refreshTokenActionError(ex.response ? ex.response.data : defaultError));
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch(clearErrorsAction());
};
