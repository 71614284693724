import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      // main: '#1976d2',
      // main: colors.blueGrey[500],
      main: colors.indigo[800],
      graph: {
        success: colors.green[500],
        error: colors.red[500]
      },
      chips: {
        success: colors.green[500],
        error: colors.red[500],
        rejected: colors.red[800],
        expired: colors.orange[500],
        default: colors.grey[500]
      }
    },
    danger: {
      contrastText: '#ffffff',
      main: '#f44336'
    },
    success: {
      contrastText: '#ffffff',
      main: colors.green[800],
      text: {
        primary: '#172b4d',
        secondary: '#6b778c'
      }
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    }
  },
  shadows,
  typography
});

export default theme;
