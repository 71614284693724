export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const TRY_LOGIN = 'TRY_LOGIN';
export const TRY_LOGIN_SUCCESS = 'TRY_LOGIN_SUCCESS';
export const TRY_LOGIN_FAILED = 'TRY_LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const SET_UNAUTHORIZED_PATH = 'SET_UNAUTHORIZED_PATH';
export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';

export const ACTIVATE = 'ACTIVATE';
export const ACTIVATE_SUCCESS = 'ACTIVATE_SUCCESS';
export const ACTIVATE_ERROR = 'ACTIVATE_ERROR';

export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RECOVER_PASSWORD_ERROR = 'RECOVER_PASSWORD_ERROR';

export const RECOVER_PASSWORD_CHANGE = 'RECOVER_PASSWORD_CHANGE';
export const RECOVER_PASSWORD_CHANGE_SUCCESS = 'RECOVER_PASSWORD_CHANGE_SUCCESS';
export const RECOVER_PASSWORD_CHANGE_ERROR = 'RECOVER_PASSWORD_CHANGE_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';

export const GET_CURRENT_CREDITS = 'GET_CURRENT_CREDITS';
export const GET_CURRENT_CREDITS_SUCCESS = 'GET_CURRENT_CREDITS_SUCCESS';

export const GET_LATEST_OPERATIONS = 'GET_LATEST_OPERATIONS';
export const GET_LATEST_OPERATIONS_SUCCESS = 'GET_LATEST_OPERATIONS_SUCCESS';

export const GET_OPERATIONS = 'GET_OPERATIONS';
export const GET_OPERATIONS_SUCCESS = 'GET_OPERATIONS_SUCCESS';

export const GET_LATEST_OPERATION_GRAPH = 'GET_LATEST_OPERATION_GRAPH';
export const GET_LATEST_OPERATION_GRAPH_SUCCESS = 'GET_LATEST_OPERATION_GRAPH_SUCCESS';

export const GET_SUBSCRIPTION = 'GET_ACTIVE_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_ACTIVE_SUBSCRIPTION_SUCCESS';

export const GET_OPERATION = 'GET_OPERATION';
export const GET_OPERATION_SUCCESS = 'GET_OPERATION_SUCCESS';

export const GET_KEYS = 'GET_KEYS';
export const GET_KEYS_SUCCESS = 'GET_KEYS_SUCCESS';

export const DELETE_KEY = 'DELETE_KEY';
export const CREATE_KEY = 'CREATE_KEY';
export const CREATE_KEY_FAILED = 'CREATE_KEY_FAILED';

export const GET_BALANCE = 'GET_BALANCE';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';

export const GET_CREDITS = 'GET_CREDITS';
export const GET_CREDITS_SUCCESS = 'GET_CREDITS_SUCCESS';

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUBSCRIPTION';

export const GET_BALANCE_GRAPH = 'GET_BALANCE_GRAPH';
export const GET_BALANCE_GRAPH_SUCCESS = 'GET_BALANCE_GRAPH_SUCCESS';

export const GET_CARDS = 'GET_CARDS';
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS';

export const DELETE_CARD = 'DELETE_CARD';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';

export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
