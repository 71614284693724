import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit'
  }
});

const Facebook = () => {
  const classes = useStyles();

  return (
    <Icon classes={{ root: classes.iconRoot }}>
      <img alt="Github" className={classes.imageIcon} src="/static/images/github-light.png" />
    </Icon>
  );
};

export default Facebook;
