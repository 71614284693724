import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Button,
  Box,
  Chip,
  TableBody,
  useTheme,
  makeStyles,
  TableContainer
} from '@material-ui/core';
import moment from 'moment';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    minHeight: 600,
    maxHeight: 600,
  },
});

const OperationBasicData = ({
  loading,
  since,
  until,
  onNewTransaction,
  onNewPayment,
  transactions
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const handleClickOpen = () => {
    if (onNewTransaction) {
      onNewTransaction();
    }
  };

  const handleClickPayment = () => {
    if (onNewTransaction) {
      onNewPayment();
    }
  };

  const getColor = (value) => {
    if (value > 0) {
      return theme.palette.primary.chips.error;
    }

    if (value === 0) {
      return theme.palette.text.primary;
    }

    return theme.palette.primary.chips.success;
  };

  const formatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
  });

  const parseTotal = (value) => formatter.format(value);

  const getType = (type) => {
    const types = {
      invoice: 'Factura',
      credit: 'Crédito',
      debit: 'Débito',
      payment: 'Pago'
    };

    return types[type];
  };

  return (
    <Card>
      <CardHeader
        subheader={`Desde el ${moment(since).format('DD/MM/YYYY')} hasta ${moment(until).format('DD/MM/YYYY')}`}
        title={t('Listado de movimientos')}
        action={(
          <>
            <Box sx={{ mr: 1, mt: 1 }}>
              <Button
                size="small"
                color="success"
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleClickPayment}
              >
                {t('Pago')}
              </Button>
              {' '}
              <Button
                size="small"
                variant="contained"
                startIcon={<AddCircleOutlineIcon />}
                onClick={handleClickOpen}
              >
                {t('Comprobante')}
              </Button>
            </Box>
          </>
        )}
      />
      <Divider />
      { transactions ? (
        <TableContainer id="table-container" className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ width: '140px' }}>Fecha</TableCell>
                <TableCell align="center" style={{ width: '140px' }}>Fecha contable</TableCell>
                <TableCell align="center" style={{ width: '140px' }}>Tipo</TableCell>
                <TableCell align="left">Descripción</TableCell>
                <TableCell align="right" style={{ width: '120px' }}>Importe</TableCell>
                <TableCell align="right" style={{ width: '120px' }}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions && transactions.length === 0 && loading === false ? (
                <TableRow hover>
                  <TableCell colSpan="6" align="center">
                    { t('There are no items to display')}
                  </TableCell>
                </TableRow>
              ) : null}
              {transactions && loading === false ? (
                transactions.map((tr) => (
                  <TableRow hover key={tr.id}>
                    <TableCell align="center">
                      {moment(tr.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell align="center">
                      {moment(tr.documentDate).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell align="center">
                      <Chip variant="outlined" size="small" label={getType(tr.type)} />
                    </TableCell>
                    <TableCell>
                      {tr.description}
                    </TableCell>
                    <TableCell align="right">
                      <strong style={{ color: getColor(tr.amount) }}>{parseTotal(tr.amount)}</strong>
                    </TableCell>
                    <TableCell align="right">
                      <strong style={{ color: getColor(tr.total) }}>{parseTotal(tr.total)}</strong>
                    </TableCell>
                  </TableRow>
                ))
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      {
        transactions == null && loading ? (
          <Grid
            container
            spacing={3}
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item md={12} xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null
      }
    </Card>
  );
};

OperationBasicData.propTypes = {
  loading: PropTypes.bool,
  since: PropTypes.string,
  until: PropTypes.string,
  onNewTransaction: PropTypes.func,
  onNewPayment: PropTypes.func,
  transactions: PropTypes.array
};

export default OperationBasicData;
