import { useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Container, Typography } from '@material-ui/core';

import Swal from 'sweetalert2';
import useQuery from '../utils/useQuery';

import { activate } from '../actions/authActions';

const Activate = () => {
  // Initialize
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Go to dashboard if logged
  const currentUser = useSelector((state) => state.auth.user);
  const activationSuccess = useSelector(
    (state) => state.auth.activationSuccess
  );

  useEffect(() => {
    if (currentUser) {
      navigate('/app', { replace: true });
    }
  }, [currentUser]);

  // Get query params
  const email = query.get('email');
  const activationCode = query.get('activationCode');

  if (email == null || activationCode == null) {
    return <Navigate to="/register" />;
  }

  useEffect(() => {
    if (activationSuccess) {
      Swal.fire({
        title: t('Success'),
        text: t('You can now enter with your username and password'),
        icon: 'success',
        confirmButtonText: t('Ok'),
        allowOutsideClick: false
      });

      navigate('/login', { replace: true });
    }
  }, [activationSuccess]);

  useEffect(() => {
    dispatch(activate(email, activationCode));
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${t('Activate')} - ${process.env.REACT_APP_COMPANY_NAME}`}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Box sx={{ mb: 3 }}>
            <Typography color="textPrimary" variant="h2">
              <Trans>Activate</Trans>
            </Typography>

            <Typography color="textSecondary" gutterBottom variant="body2">
              <Trans>Please wait while we activate your account...</Trans>
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Activate;
