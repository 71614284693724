import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';

import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
  Button,
  IconButton,
  useTheme
} from '@material-ui/core';

const OperationsList = ({
  operations,
  loading,
  title,
  bottom,
  onNewClient,
  onEditClient,
  onDeleteClient
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClickOpen = () => {
    if (onNewClient) {
      onNewClient();
    }
  };

  const handleDelete = (client) => {
    if (onDeleteClient) {
      onDeleteClient(client);
    }
  };

  const handleEdit = (client) => {
    if (onEditClient) {
      onEditClient(client);
    }
  };

  const getColor = (value) => {
    if (value > 0) {
      return theme.palette.primary.chips.error;
    }

    if (value === 0) {
      return theme.palette.text.primary;
    }

    return theme.palette.primary.chips.success;
  };

  const formatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
  });

  const parseTotal = (value) => formatter.format(value);

  return (
    <Card>
      <CardHeader
        title={title}
        action={(
          <Button
            size="small"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleClickOpen}
          >
            {t('Nuevo cliente')}
          </Button>
        )}
      />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">{t('Nombre')}</TableCell>
                <TableCell align="right" style={{ width: '15%' }}>{t('Saldo')}</TableCell>
                <TableCell style={{ width: '120px' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {operations && operations.results.length === 0 && loading === false ? (
                <TableRow hover>
                  <TableCell colSpan="6" align="center">
                    { t('There are no items to display')}
                  </TableCell>
                </TableRow>
              ) : null}
              {operations && operations.results && loading === false ? (
                operations.results.map((operation) => (
                  <TableRow hover key={operation.id}>
                    <TableCell>
                      <Link
                        to={`/app/clients/${operation.id}`}
                        component={RouterLink}
                      >
                        {operation.name.toUpperCase()}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <strong style={{ color: getColor(operation.total) }}>{parseTotal(operation.total)}</strong>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="primary"
                        component="span"
                        size="small"
                        onClick={() => handleEdit(operation)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        aria-label={t('Delete')}
                        component="span"
                        size="small"
                        onClick={() => handleDelete(operation)}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow hover>
                  <TableCell colSpan="6" align="center"><CircularProgress /></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {operations && operations.results ? bottom : null}
    </Card>
  );
};

OperationsList.propTypes = {
  loading: PropTypes.bool,
  operations: PropTypes.object,
  title: PropTypes.string,
  bottom: PropTypes.object,
  onNewClient: PropTypes.func,
  onEditClient: PropTypes.func,
  onDeleteClient: PropTypes.func
};

export default OperationsList;
