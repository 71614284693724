import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';

import { setUnauthorizedPath } from '../actions/authActions';
import setLanguage from '../actions/languageActions';
import DashboardNavbar from './DashboardNavbar';
import SessionTimeout from './SessionTimeout';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (user == null) {
      dispatch(setUnauthorizedPath(location.pathname + location.search));
      navigate('/login', { replace: true });
    }
  }, [user]);

  useEffect(() => {
    console.log('Refreshing: DashboardLayout');
  }, [i18n]);

  i18n.on('languageChanged', (lng) => {
    dispatch(setLanguage(lng));
    moment.locale(lng);
  });

  if (user) {
    return (
      <DashboardLayoutRoot>
        <DashboardNavbar onMobileNavOpen={() => {}} />
        <DashboardLayoutWrapper>
          <DashboardLayoutContainer>
            <DashboardLayoutContent>
              <SessionTimeout seconds={60} />
              <Outlet />
            </DashboardLayoutContent>
          </DashboardLayoutContainer>
        </DashboardLayoutWrapper>
      </DashboardLayoutRoot>
    );
  }

  return null;
};

export default DashboardLayout;
