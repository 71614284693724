import { useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@material-ui/core';

import Swal from 'sweetalert2';
import useQuery from '../utils/useQuery';
import { recoverPasswordChange } from '../actions/authActions';

const RecoverPasswordChange = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Go to dashboard if logged
  const currentUser = useSelector((state) => state.auth.user);
  const recoverPasswordChangeSuccess = useSelector(
    (state) => state.auth.recoverPasswordChangeSuccess
  );

  useEffect(() => {
    if (currentUser) {
      navigate('/app', { replace: true });
    }
  }, [currentUser]);

  // Get query params
  const email = query.get('email');
  const recoverCode = query.get('recoverCode');

  if (email == null || recoverCode == null) {
    return <Navigate to="/login" />;
  }

  const handleRecover = (form) => {
    dispatch(recoverPasswordChange({
      ...form,
      email,
      recoverCode
    }));
  };

  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);

  useEffect(() => {
    if (recoverPasswordChangeSuccess) {
      Swal.fire({
        title: t('Success'),
        text: t('You can now enter with your e-mail and the new password'),
        icon: 'success',
        confirmButtonText: t('Ok'),
        allowOutsideClick: false
      });
      navigate('/login', { replace: true });
    }
  }, [recoverPasswordChangeSuccess]);

  return (
    <>
      <Helmet>
        <title>{`${t('Recover Password')} - ${process.env.REACT_APP_COMPANY_NAME}`}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().max(255).required(t('Password is required'))
            })}
            onSubmit={handleRecover}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    <Trans>Recover Password</Trans>
                  </Typography>

                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    <Trans>
                      Almost done, enter your new password
                    </Trans>
                  </Typography>
                </Box>

                {error ? (
                  <Box sx={{ mb: 3 }}>
                    <Alert severity="error">{error.message}</Alert>
                  </Box>
                ) : null}

                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t('Password')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={loading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    <Trans>Send</Trans>
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default RecoverPasswordChange;
