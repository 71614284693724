import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import OperationsList from 'src/components/operations/OperationsList';
import useQuery from '../utils/useQuery';
import API from '../utils/api';

const Operations = () => {
  const query = useQuery();

  const receivedPage = query.get('page');
  const receivedLimit = query.get('limit');
  const initialPage = receivedPage ? parseInt(receivedPage, 10) : 1;
  const initialLimit = receivedLimit ? parseInt(receivedLimit, 10) : 25;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [page, setPage] = useState(initialPage);
  const [limit, setLimit] = useState(initialLimit);
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState(null);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const getClients = async () => {
    setLoading(true);

    const response = await API().get('v1/clients', {
      params: {
        page,
        limit
      }
    });

    setLoading(false);
    setClients(response.data);
  };

  useEffect(() => {
    getClients(page, limit);
    navigate(`?page=${page}&limit=${limit}`);
  }, [page, limit]);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleUpdate = async (values, { resetForm }) => {
    let response;
    setLoadingCreate(true);

    const data = {
      ...values,
      name: values.name.toUpperCase()
    };

    if (selectedClient) {
      await API().put(`v1/clients/${selectedClient.id}`, data);
    } else {
      response = await API().post('v1/clients', data);
    }

    resetForm();
    setLoadingCreate(false);
    setOpen(false);
    getClients();

    if (response) {
      navigate(`/app/clients/${response.data.id}`, { replace: true });
    }
  };

  const labelFunction = ({ from, to, count }) => {
    const quantity = count !== -1 ? count : `more than ${to}`;
    return `${from}-${to} ${t('of')} ${quantity}`;
  };

  const onNewClient = () => {
    setSelectedClient(null);
    setOpen(true);
  };

  const onEditClient = (client) => {
    setSelectedClient(client);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDeleteAction = async () => {
    if (selectedClient) {
      await API().delete(`v1/clients/${selectedClient.id}`);
      setSelectedClient(null);
      setOpenDelete();
      getClients();
    }
  };

  const onDeleteClient = (client) => {
    setSelectedClient(client);
    setOpenDelete(true);
  };

  return (
    <>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        fullWidth
        maxWidth="sm"
      >
        {
          selectedClient ? (
            <>
              <DialogTitle>{t('¿Eliminar este cliente?')}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Box fontFamily="Roboto Mono">{ selectedClient.name }</Box>
                </DialogContentText>
              </DialogContent>
            </>
          ) : null
        }
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleDeleteAction} color="secondary">
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{selectedClient ? 'Editar cliente' : 'Nuevo Cliente'}</DialogTitle>
        <Formik
          initialValues={{
            name: selectedClient ? selectedClient.name : ''
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required(t('Se debe ingresar el nombre'))
          })}
          onSubmit={handleUpdate}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit} noValidate>
              <DialogContent>
                <TextField
                  autoFocus
                  fullWidth
                  error={Boolean(touched.name && errors.name)}
                  label={t('Nombre del cliente')}
                  name="name"
                  disabled={loadingCreate}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant="outlined"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={loadingCreate}>
                  {t('Cancel')}
                </Button>
                <Button type="submit" color="primary" disabled={loadingCreate}>
                  { selectedClient ? 'Guardar' : 'Crear' }
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Helmet>
        <title>{`${t('Clientes')} - ${process.env.REACT_APP_COMPANY_NAME}`}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ pt: 3 }}>
            <OperationsList
              operations={clients}
              loading={loading}
              title={t('Clientes')}
              onNewClient={onNewClient}
              onEditClient={onEditClient}
              onDeleteClient={onDeleteClient}
              bottom={(
                <TablePagination
                  component="div"
                  count={clients ? clients.total : 0}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  labelRowsPerPage={t('Rows per page')}
                  backIconButtonText={t('Previous page')}
                  nextIconButtonText={t('Next page')}
                  page={page - 1}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[25, 50, 100]}
                  labelDisplayedRows={labelFunction}
                />
              )}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Operations;
