import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import {
  Alert,
  Box,
  Button,
  Grid,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import GithubIcon from 'src/icons/Github';
import GoogleIcon from 'src/icons/Google';

import { register, clearErrors, loginWithThirdParty } from '../actions/authActions';

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);

  // Clear previous errors
  useEffect(() => {
    dispatch(clearErrors());
  }, []);

  useEffect(() => {
    if (currentUser) {
      navigate('/app', { replace: true });
    }
  }, [currentUser]);

  const { t, i18n } = useTranslation();

  const handleRegister = (form) => {
    const data = {
      ...form,
      language: i18n.language,
      policy: undefined
    };

    dispatch(register(data));
  };

  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const registerSuccess = useSelector((state) => state.auth.registerSuccess);

  const handleSubmitWithGoogle = () => {
    dispatch(loginWithThirdParty({
      name: 'google',
      language: i18n.language
    }));
  };

  const handleSubmitWithGithub = () => {
    dispatch(loginWithThirdParty({
      name: 'github',
      language: i18n.language
    }));
  };

  useEffect(() => {
    if (registerSuccess) {
      Swal.fire({
        title: t('Please check your email'),
        text: t('We have sent you an email to activate your account.'),
        icon: 'success',
        confirmButtonText: t('Ok'),
        allowOutsideClick: false
      });

      navigate('/login', { replace: true });
    }
  }, [registerSuccess]);

  return (
    <>
      <Helmet>
        <title>{`${t('Register')} - ${process.env.REACT_APP_COMPANY_NAME}`}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
              password: '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email(t('Must be a valid email')).max(255).required(t('Email is required')),
                firstName: Yup.string().max(255).required(t('First name is required')),
                lastName: Yup.string().max(255).required(t('Last name is required')),
                password: Yup.string().max(255).required(t('Password is required')),
                policy: Yup.boolean().oneOf([true], t('This field must be checked'))
              })
            }
            onSubmit={handleRegister}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    <Trans>Create new account</Trans>
                  </Typography>
                </Box>

                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Button
                      color="primary"
                      fullWidth
                      startIcon={<GoogleIcon />}
                      onClick={handleSubmitWithGoogle}
                      disabled={loading}
                      size="large"
                      variant="contained"
                    >
                      <Trans>Register with Google</Trans>
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Button
                      fullWidth
                      startIcon={<GithubIcon />}
                      onClick={handleSubmitWithGithub}
                      disabled={loading}
                      size="large"
                      variant="contained"
                    >
                      <Trans>Register with Github</Trans>
                    </Button>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    pb: 1,
                    pt: 3
                  }}
                >
                  <Typography
                    align="left"
                    color="textSecondary"
                    variant="body1"
                  >
                    <Trans>Or log in with email address</Trans>
                  </Typography>
                </Box>

                {
                  error ? (
                    <Box sx={{ mb: 3 }}>
                      <Alert severity="error">{ t(error.message) }</Alert>
                    </Box>
                  ) : null
                }

                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label={t('First name')}
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label={t('Last name')}
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('Email Address')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t('Password')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: -1
                  }}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    <Trans>I have read the</Trans>
                    {' '}
                    <Link
                      color="primary"
                      component="a"
                      href={process.env.REACT_APP_TERMS_URL}
                      target="_blank"
                      underline="always"
                      variant="h6"
                    >
                      <Trans>Terms and Conditions</Trans>
                    </Link>
                    {' '}
                    <Trans>and the</Trans>
                    {' '}
                    <Link
                      color="primary"
                      component="a"
                      href={process.env.REACT_APP_PRIVACY_URL}
                      target="_blank"
                      underline="always"
                      variant="h6"
                    >
                      <Trans>Privacy policy</Trans>
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={loading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    <Trans>Sign up now</Trans>
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  <Trans>Have an account?</Trans>
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    <Trans>Log in</Trans>
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Register;
