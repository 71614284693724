import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core';
import GoogleIcon from 'src/icons/Google';

import {
  login,
  tryLogin,
  clearErrors,
  loginWithThirdParty
} from '../actions/authActions';

import setLanguage from '../actions/languageActions';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Clear previous errors
  useEffect(() => {
    dispatch(clearErrors());
  }, []);

  // Try to login with session storage
  useEffect(() => {
    dispatch(tryLogin());
  }, [dispatch]);

  const handleSignIn = (form) => {
    dispatch(login(form));
  };

  const currentUser = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const unauthorizedPath = useSelector((state) => state.auth.unauthorizedPath);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (currentUser) {
      const path = unauthorizedPath || '/app';

      // Set user language
      i18n.changeLanguage(currentUser.language).then(() => {
        dispatch(setLanguage(currentUser.language));
      });

      navigate(path, { replace: true });
    }
  }, [currentUser, unauthorizedPath]);

  const handleSubmitWithGoogle = () => {
    dispatch(loginWithThirdParty({
      name: 'google',
      language: i18n.language
    }));
  };

  return (
    <>
      <Helmet>
        <title>{`${t('Log in')} - ${process.env.REACT_APP_COMPANY_NAME}`}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">

          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email(t('Must be a valid email')).max(255).required(t('Email is required')),
              password: Yup.string().max(255).required(t('Password is required'))
            })}
            onSubmit={handleSignIn}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    <Trans>Log in</Trans>
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    md={12}
                  >
                    <Button
                      color="primary"
                      fullWidth
                      startIcon={<GoogleIcon />}
                      onClick={handleSubmitWithGoogle}
                      disabled={loading}
                      size="large"
                      variant="contained"
                    >
                      <Trans>Login with Google</Trans>
                    </Button>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    pb: 1,
                    pt: 3
                  }}
                >
                  <Typography
                    align="left"
                    color="textSecondary"
                    variant="body1"
                  >
                    <Trans>Or log in with email address</Trans>
                  </Typography>
                </Box>
                {
                  error ? (
                    <Box sx={{ mb: 3 }}>
                      <Alert severity="error">{ t(error.message) }</Alert>
                    </Box>
                  ) : null
                }
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t('Email Address')}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t('Password')}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={loading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    <Trans>Log in now</Trans>
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
