import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  TRY_LOGIN,
  TRY_LOGIN_SUCCESS,
  TRY_LOGIN_FAILED,
  LOGOUT,
  LOGOUT_SUCCESS,
  SET_UNAUTHORIZED_PATH,
  ACTIVATE,
  ACTIVATE_SUCCESS,
  ACTIVATE_ERROR,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_ERROR,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_CHANGE,
  RECOVER_PASSWORD_CHANGE_SUCCESS,
  RECOVER_PASSWORD_CHANGE_ERROR,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  CLEAR_AUTH_ERRORS
} from '../types';

const initialState = {
  user: null,
  token: null,
  error: null,
  loading: false,
  unauthorizedPath: null,
  registerSuccess: false,
  activationSuccess: false,
  recoverPasswordSuccess: false,
  recoverPasswordChangeSuccess: false,
  usingPassword: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
    case TRY_LOGIN: {
      return {
        ...state,
        token: null,
        error: null,
        loading: true,
        registerSuccess: false,
        activationSuccess: false,
        recoverPasswordSuccess: false,
        recoverPasswordChangeSuccess: false
      };
    }
    case TRY_LOGIN_SUCCESS:
    case LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        usingPassword: action.payload.usingPassword,
        loading: false,
        registerSuccess: false,
        activationSuccess: false,
        recoverPasswordSuccess: false,
        recoverPasswordChangeSuccess: false
      };
    }
    case TRY_LOGIN_FAILED: {
      return {
        ...state,
        user: null,
        token: null,
        error: null,
        loading: false,
        registerSuccess: false,
        activationSuccess: false,
        recoverPasswordSuccess: false,
        recoverPasswordChangeSuccess: false
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        user: null,
        token: null,
        error: action.payload,
        loading: false,
        registerSuccess: false,
        activationSuccess: false,
        recoverPasswordSuccess: false,
        recoverPasswordChangeSuccess: false
      };
    }
    case REGISTER: {
      return {
        ...state,
        loading: true,
        registerSuccess: false,
        activationSuccess: false,
        recoverPasswordSuccess: false,
        recoverPasswordChangeSuccess: false
      };
    }
    case REGISTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        registerSuccess: true,
        activationSuccess: false,
        recoverPasswordSuccess: false,
        recoverPasswordChangeSuccess: false
      };
    }
    case REGISTER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false,
        registerSuccess: false,
        activationSuccess: false,
        recoverPasswordSuccess: false,
        recoverPasswordChangeSuccess: false
      };
    }
    case LOGOUT: {
      return state;
    }
    case SET_UNAUTHORIZED_PATH: {
      return {
        ...state,
        unauthorizedPath: action.payload
      };
    }
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    case ACTIVATE: {
      return {
        ...state,
        loading: true,
        error: null,
        activationSuccess: false,
      };
    }
    case ACTIVATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        activationSuccess: true
      };
    }
    case ACTIVATE_ERROR: {
      return {
        ...state,
        loading: false,
        activationSuccess: false
      };
    }
    case RECOVER_PASSWORD: {
      return {
        ...state,
        loading: true,
        error: null,
        recoverPasswordSuccess: false
      };
    }
    case CLEAR_AUTH_ERRORS: {
      return {
        ...state,
        error: null,
      };
    }
    case RECOVER_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        recoverPasswordSuccess: true
      };
    }
    case RECOVER_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        recoverPasswordSuccess: false
      };
    }
    case RECOVER_PASSWORD_CHANGE: {
      return {
        ...state,
        loading: true,
        recoverPasswordChangeSuccess: false
      };
    }
    case RECOVER_PASSWORD_CHANGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        recoverPasswordChangeSuccess: true
      };
    }
    case RECOVER_PASSWORD_CHANGE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        recoverPasswordChangeSuccess: false
      };
    }
    case UPDATE_USER: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload
      };
    }
    case UPDATE_USER_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.payload.token,
      };
    }
    default:
      return state;
  }
};
