import { AppBar, Box, Toolbar } from '@material-ui/core';

const MainNavbar = (props) => (
  <AppBar
    elevation={0}
    {...props}
  >
    <Toolbar sx={{ height: 64 }}>
      <Box sx={{ flexGrow: 1 }} />
    </Toolbar>
  </AppBar>
);

export default MainNavbar;
