import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Operations from 'src/pages/Operations';
import OperationDetails from 'src/pages/OperationDetails';
// import CustomDashboard from 'src/pages/CustomDashboard';
import Login from 'src/pages/Login';
import Activate from 'src/pages/Activate';
import RecoverPassword from 'src/pages/RecoverPassword';
import RecoverPasswordChange from 'src/pages/RecoverPasswordChange';
import NotFound from 'src/pages/NotFound';
import Register from 'src/pages/Register';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: '/', element: <Navigate to="/app/home" /> },
      { path: 'home', element: <Operations /> },
      { path: 'clients/:id', element: <OperationDetails /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: 'activate', element: <Activate /> },
      { path: 'recover-password', element: <RecoverPassword /> },
      { path: 'recover-password-change', element: <RecoverPasswordChange /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
