import { Suspense } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import firebase from 'firebase/app';

import { Provider } from 'react-redux';
import store from './utils/store';
import '@sweetalert2/theme-material-ui/material-ui.scss';
import './common/i18n';
import './custom.css';

const firebaseConfig = {
  apiKey: 'AIzaSyDMMlq9IlQeJjJv3pT8poVfh94c3_94k7g',
  authDomain: 'clientes.mueblesmc.com.ar',
  projectId: 'mcm-clientes',
  storageBucket: 'mcm-clientes.appspot.com',
  messagingSenderId: '47863352578',
  appId: '1:47863352578:web:e721c4743e7ec6ad4d461b'
};

firebase.initializeApp(firebaseConfig);

const App = () => {
  const routing = useRoutes(routes);

  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {routing}
        </ThemeProvider>
      </Provider>
    </Suspense>
  );
};

export default App;
