import {
  GET_LATEST_OPERATIONS,
  GET_LATEST_OPERATIONS_SUCCESS,
  GET_LATEST_OPERATION_GRAPH,
  GET_LATEST_OPERATION_GRAPH_SUCCESS,
  GET_OPERATIONS,
  GET_OPERATIONS_SUCCESS,
  GET_OPERATION,
  GET_OPERATION_SUCCESS
} from '../types';

const initialState = {
  loadingLatestOperations: false,
  latestOperations: null,
  loadingLatestOperationGraph: false,
  latestOperationGraph: null,
  operations: null,
  loadingOperations: false,
  selectOperation: null,
  loadingSelectOperation: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LATEST_OPERATIONS: {
      return {
        ...state,
        loadingLatestOperations: true
      };
    }
    case GET_LATEST_OPERATIONS_SUCCESS: {
      return {
        ...state,
        loadingLatestOperations: false,
        latestOperations: action.payload
      };
    }
    case GET_LATEST_OPERATION_GRAPH: {
      return {
        ...state,
        loadingLatestOperationGraph: true,
        latestOperationGraph: null
      };
    }
    case GET_LATEST_OPERATION_GRAPH_SUCCESS: {
      return {
        ...state,
        loadingLatestOperationGraph: false,
        latestOperationGraph: action.payload
      };
    }
    case GET_OPERATIONS: {
      return {
        ...state,
        loadingOperations: true
      };
    }
    case GET_OPERATIONS_SUCCESS: {
      return {
        ...state,
        loadingOperations: false,
        operations: action.payload
      };
    }
    case GET_OPERATION: {
      return {
        ...state,
        loadingSelectOperation: true
      };
    }
    case GET_OPERATION_SUCCESS: {
      return {
        ...state,
        loadingSelectOperation: false,
        selectOperation: action.payload
      };
    }
    default:
      return state;
  }
};
