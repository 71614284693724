import { combineReducers } from 'redux';
import authReducer from './authReducer';
import languageReducer from './languageReducer';
import balanceReducer from './balanceReducer';
import operationsReducer from './operationsReducer';
import keysReducer from './keysReducer';
import { LOGOUT_SUCCESS } from '../types';

const appReducer = combineReducers({
  auth: authReducer,
  language: languageReducer,
  balance: balanceReducer,
  operations: operationsReducer,
  keys: keysReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
