import axios from 'axios';

export default () => {
  const storage = sessionStorage.getItem('session');
  const session = storage ? JSON.parse(storage) : null;

  return axios.create({
    baseURL: process.env.REACT_APP_DASHBOARD_API_URL,
    headers: {
      Authorization: session ? session.token : undefined
    }
  });
};
