import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import {
  Breadcrumbs,
  Box,
  Container,
  Grid,
  Link,
  Typography,
  Divider,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  ButtonGroup
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import OperationBasicData from 'src/components/operations/OperationBasicData';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import moment from 'moment';
import API from '../utils/api';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const OperationDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();

  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(null);
  const [addingPayment, setAddingPayment] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [transactions, setTransactions] = useState(null);
  const [since, setSince] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));
  const [until, setUntil] = useState(moment().format('YYYY-MM-DD'));
  const [transactionType, setTransactionType] = useState('invoice');

  const handleClick = (e) => {
    e.preventDefault();
    navigate('/app/home', { replace: true });
  };

  const requestClient = async () => {
    const response = await API().get(`v1/clients/${id}`);
    setClient(response.data);
  };

  const requestTransactions = async () => {
    setLoading(true);

    const response = await API().get(`v1/clients/${id}/transactions`, {
      params: {
        since,
        until
      }
    });

    setTransactions(response.data);
    setLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      const div = document.getElementById('table-container');
      if (div) {
        div.scrollTop = div.scrollHeight;
      }
    }, 100);
  }, [transactions]);

  useEffect(() => {
    requestClient();
    requestTransactions();
  }, []);

  useEffect(() => {
    requestTransactions();
  }, [since, until]);

  const changeSince = (e) => {
    setSince(e.target.value);
  };

  const changeUntil = (e) => {
    setUntil(e.target.value);
  };

  const handleUpdate = async (values, { resetForm }) => {
    setLoadingCreate(true);

    await API().post(`v1/clients/${id}/transactions`, {
      ...values,
      type: addingPayment ? 'payment' : transactionType
    });

    setLoadingCreate(false);
    resetForm();
    requestTransactions();
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onNewTransaction = () => {
    setAddingPayment(false);
    setOpen(true);
  };

  const onNewPayment = () => {
    setAddingPayment(true);
    setOpen(true);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{addingPayment ? 'Nuevo pago' : 'Nuevo comprobante'}</DialogTitle>
        <Formik
          initialValues={{
            documentDate: moment().format('YYYY-MM-DD'),
            description: '',
            amount: null
          }}
          validationSchema={Yup.object().shape({
            documentDate: Yup.date().required('Se debe ingresar la fecha contable'),
            description: Yup.string().max(255),
            amount: Yup.number().min(0.01).required(t('Se debe ingresar un importe'))
          })}
          onSubmit={handleUpdate}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit} noValidate>
              <DialogContent>
                <Grid container spacing={3}>
                  {
                    addingPayment === false ? (
                      <>
                        <Grid item lg={12} md={12} xs={12}>
                          <ButtonGroup disabled={loadingCreate} fullWidth color="primary" aria-label="outlined primary button group">
                            <Button onClick={() => setTransactionType('invoice')} variant={transactionType === 'invoice' ? 'contained' : null}>Factura</Button>
                            <Button onClick={() => setTransactionType('debit')} variant={transactionType === 'debit' ? 'contained' : null}>Débito</Button>
                            <Button onClick={() => setTransactionType('credit')} variant={transactionType === 'credit' ? 'contained' : null}>Crédito</Button>
                          </ButtonGroup>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                          <Divider />
                        </Grid>
                      </>
                    ) : null
                  }
                  <Grid item lg={12} md={12} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.documentDate && errors.documentDate)}
                      label={t('Fecha contable')}
                      name="documentDate"
                      disabled={loadingCreate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      type="date"
                      value={values.documentDate}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <TextField
                      autoFocus
                      fullWidth
                      error={Boolean(touched.description && errors.description)}
                      label={t('Descripción')}
                      name="description"
                      disabled={loadingCreate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.amount && errors.amount)}
                      label={t('Importe')}
                      name="amount"
                      disabled={loadingCreate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      min="0.01"
                      step=".01"
                      value={values.amount}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={loadingCreate}>
                  {t('Cancel')}
                </Button>
                <Button type="submit" color="primary" disabled={loadingCreate}>
                  Guardar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Helmet>
        <title>{`${client ? client.name : 'Cargando'} - ${process.env.REACT_APP_COMPANY_NAME}`}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
          mb: 3
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="#" onClick={handleClick} className={classes.link}>
                  <ChevronLeftIcon className={classes.icon} />
                  {t('Clientes')}
                </Link>
                <Typography color="textPrimary">{t('Cuenta corriente')}</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box
            sx={{
              my: 2
            }}
          >
            <Divider />
          </Box>
          <Box
            sx={{
              my: 2
            }}
          >
            <Typography
              color="textPrimary"
              variant="h1"
            >
              {client ? client.name : 'Cargando...'}
            </Typography>
          </Box>
          <Box
            sx={{
              my: 4
            }}
          >
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xs={6}>
                    <TextField
                      fullWidth
                      id="since"
                      label="Desde"
                      type="date"
                      className={classes.textField}
                      value={since}
                      onChange={changeSince}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={6}>
                    <TextField
                      fullWidth
                      id="until"
                      label="Hasta"
                      type="date"
                      value={until}
                      onChange={changeUntil}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <OperationBasicData
                since={since}
                until={until}
                transactions={transactions}
                loading={loading}
                onNewTransaction={onNewTransaction}
                onNewPayment={onNewPayment}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default OperationDetails;
