import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Snackbar, Alert, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import jwtDecode from 'jwt-decode';
import moment from 'moment';

import {
  refreshToken,
  logout
} from '../actions/authActions';

let timeoutSnackbar = null;
let timeoutExpire = null;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const SessionTimeout = ({ seconds }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    clearTimeout(timeoutSnackbar);
    clearTimeout(timeoutExpire);
    setOpen(false);

    if (token) {
      const decoded = jwtDecode(token);
      const iat = moment.unix(decoded.iat);
      const exp = moment.unix(decoded.exp);

      const expirationMs = exp.diff(iat);
      const snackbarMs = expirationMs - (seconds * 1000);

      timeoutSnackbar = setTimeout(() => {
        setOpen(true);
      }, snackbarMs);

      timeoutExpire = setTimeout(() => {
        setOpen(false);
        dispatch(logout());
      }, expirationMs);
    }
  }, [token]);

  const refresh = () => {
    dispatch(refreshToken());
  };

  const action = (
    <Button color="primary" variant="contained" size="small" onClick={refresh}>
      {t('Yes, Im here')}
    </Button>
  );

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="info" elevation={6} variant="filled" action={action}>
          {t('Your session is about to expire. Are you there?')}
        </Alert>
      </Snackbar>
    </div>
  );
};

SessionTimeout.propTypes = {
  seconds: PropTypes.number,
};

export default SessionTimeout;
