import {
  GET_KEYS,
  GET_KEYS_SUCCESS,
  DELETE_KEY,
  CREATE_KEY,
  CREATE_KEY_FAILED
} from '../types';

const initialState = {
  loading: false,
  keys: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_KEYS: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_KEYS_SUCCESS: {
      return {
        ...state,
        loading: false,
        keys: action.payload
      };
    }
    case DELETE_KEY: {
      return {
        ...state,
        loading: true
      };
    }
    case CREATE_KEY: {
      return {
        ...state,
        loading: true
      };
    }
    case CREATE_KEY_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    default:
      return state;
  }
};
