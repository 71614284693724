import {
  GET_CURRENT_CREDITS,
  GET_CURRENT_CREDITS_SUCCESS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  GET_BALANCE,
  GET_BALANCE_SUCCESS,
  GET_CREDITS,
  GET_CREDITS_SUCCESS,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  GET_BALANCE_GRAPH,
  GET_BALANCE_GRAPH_SUCCESS,
  GET_CARDS,
  GET_CARDS_SUCCESS,
  DELETE_CARD,
  DELETE_CARD_SUCCESS
} from '../types';

const initialState = {
  loading: false,
  lastTransaction: null,
  loadingSubscription: false,
  activeSubscription: null,
  balance: null,
  loadingBalance: null,
  loadingCredits: false,
  credits: null,
  balanceGraph: null,
  loadingBalanceGraph: false,
  loadingCards: false,
  cards: null,
  loadingCreateSubscription: false,
  pendingCredits: false,
  suscriptionLoaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_CREDITS: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_CURRENT_CREDITS_SUCCESS: {
      return {
        ...state,
        loading: false,
        lastTransaction: action.payload
      };
    }
    case GET_SUBSCRIPTION: {
      return {
        ...state,
        loadingSubscription: true
      };
    }
    case GET_SUBSCRIPTION_SUCCESS: {
      const subscription = action.payload.results.find((s) => s.type === 'recurrent');
      const pendingSubscription = action.payload.results.filter((s) => s.lastCreditAt === null);

      return {
        ...state,
        loadingSubscription: false,
        activeSubscription: subscription || null,
        suscriptionLoaded: true,
        pendingCredits: (pendingSubscription.length > 0)
      };
    }
    case GET_BALANCE: {
      return {
        ...state,
        loadingBalance: true,
      };
    }
    case GET_BALANCE_SUCCESS: {
      return {
        ...state,
        loadingBalance: false,
        balance: action.payload
      };
    }
    case GET_CREDITS: {
      return {
        ...state,
        loadingCredits: true,
      };
    }
    case GET_CREDITS_SUCCESS: {
      return {
        ...state,
        loadingCredits: false,
        credits: action.payload
      };
    }
    case CANCEL_SUBSCRIPTION: {
      return {
        ...state,
        loadingSubscription: true,
        activeSubscription: null
      };
    }
    case CANCEL_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        loadingSubscription: false,
        activeSubscription: null
      };
    }
    case GET_BALANCE_GRAPH: {
      return {
        ...state,
        loadingBalanceGraph: true,
      };
    }
    case GET_BALANCE_GRAPH_SUCCESS: {
      return {
        ...state,
        loadingBalanceGraph: false,
        balanceGraph: action.payload
      };
    }
    case GET_CARDS: {
      return {
        ...state,
        loadingCards: true,
      };
    }
    case GET_CARDS_SUCCESS: {
      return {
        ...state,
        loadingCards: false,
        cards: action.payload
      };
    }
    case DELETE_CARD: {
      return {
        ...state,
        loadingCards: true,
      };
    }
    case DELETE_CARD_SUCCESS: {
      return {
        ...state,
        loadingCards: false,
        cards: {
          ...state.cards,
          data: state.cards.data.filter((c) => c.id !== action.payload)
        }
      };
    }
    default:
      return state;
  }
};
