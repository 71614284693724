// import api from '../config/api';

import { LANGUAGE_CHANGED } from '../types';

// Actions
const changeLanguage = (lng) => ({
  type: LANGUAGE_CHANGED,
  payload: lng
});

const setLanguage = (lng) => async (dispatch) => {
  dispatch(changeLanguage(lng));
};

export default setLanguage;
